import axios from 'axios'

export class AreaConhecimento {
  static async obtemTodos() {
    return axios.get('/area-conhecimento')
  }
  static async obtemUm(id) {
    return axios.get('/area-conhecimento/' + id)
  }
  static async cadastrar(payload) {
    return axios.post('/area-conhecimento', payload)
  }
  static async alterar(payload) {
    return axios.put('/area-conhecimento/' + payload.id, payload)
  }
  static async remover(id) {
    return axios.delete('/area-conhecimento/' + id)
  }
}
